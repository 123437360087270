.select
{
    opacity: 0.6 ;
}


.careers-container
{
  display: flex;
  max-width: 1024px;
  justify-content: center;
  align-items: center;
  padding: 13px;
  color: #353535;
}
