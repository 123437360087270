.phone-link,
.main-link {
  text-decoration: none;
}

input,
textarea {
  opacity: 0.6;
}
.btn.btn-success {
  background-color: #2f7ee8;
}
.btn.btn-success:active {
  background-color: #2f7ee8;
}
.btn.btn-success:hover {
  background-color: #2f7ee8;
}

.map-container {
  border: white solid 3px;
}

.contact-info-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-container {
  color: #353535;
  max-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
}
