@media only screen and (max-width: 760px) {
  .card {
    margin-top: 30px;
  }
  .services-container {
    font-size: 1rem;
    padding: 0 35px !important;
    width: 100%;
    margin-top: 20px;
  }
}

.services-container {
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-content {
  max-width: 800px;
  color: #353535;
}

.card-container {
  .card {
    background-color:rgba(255, 255, 255, 0.4);
    border-color: #fff;
    color: #353535;
  }
}
