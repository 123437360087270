
table{
    margin-top: 30px;
}
i:hover {
    cursor:grab;
}
Form.Select{
    width: 50px;
}

.close-button
{
    align-items: left;
    width: 200px;
}


.update-button
{
    align-items: end;
}