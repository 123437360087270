.service-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  max-width: 1024px;
  color: #353535;
  .card {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.4);
    border-color: #fff;
    color: #353535;
    margin-bottom: 10px;
  }
}
