.home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  height: 100%;
}

.home-content {
  display: flex;
  max-width: 1024px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #353535;
}

.home-section p {
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  .home-section h1 {
    font-size: 1.5rem;
  }
  .home-section p {
    font-size: 1rem;
  }
}
